import React, { MouseEventHandler, ReactEventHandler, useRef } from 'react';
import styles from './CartDishItem.scss';
import Quantity from '../Quantity';
import Text from '../../core-components/Text';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import Error from 'wix-ui-icons-common/on-stage/Error';
import dataHooks from '../../data-hooks';
import { Element } from 'react-scroll';
import { useTranslation } from 'yoshi-flow-editor-runtime';
import classnames from 'classnames';

export interface CartDishItemProps {
  title: string;
  count: number;
  price: string;
  options?: string[];
  error?: string;
  comment?: string;
  onEdit: ReactEventHandler;
  onRemove: ReactEventHandler;
  onAmountChange: (newAmount: number) => void;
  hook?: string;
  isCartImmutable?: boolean;
  isLast?: boolean;
}

function targetNotInsideRef(target: any, ref: React.RefObject<HTMLElement>) {
  return ref.current === null || !ref.current.contains(target);
}

const stopPropagation: MouseEventHandler<HTMLDivElement> = (e) => {
  e.stopPropagation();
};

const CartDishItem: React.FC<CartDishItemProps> = ({
  title,
  price,
  count,
  options = [],
  comment,
  error,
  onEdit,
  onRemove,
  onAmountChange,
  hook,
  isCartImmutable,
  isLast,
}) => {
  const quantityRef = useRef<HTMLDivElement>(null);
  const removeRef = useRef<HTMLDivElement>(null);

  const idSuffix = Math.random();
  const dynamicId = `cart-dish-item-${idSuffix}`;
  const { t } = useTranslation();

  const quantity = (
    <div onClick={stopPropagation}>
      <Quantity
        min={0}
        small
        onChange={(val) => {
          if (val) {
            onAmountChange(Number(val));
          }
        }}
        aria-label={t('DISH_SCREEN_SCREENREADER_QTYSELECTED_TEXT')}
        inputAriaLabel={t('DISH_SCREEN_QUANTITY')}
        incrementAriaLabel={t('DISH_SCREEN_SCREENREADER_INCREASE_TEXT')}
        decrementAriaLabel={t('DISH_SCREEN_SCREENREADER_DECREASE_TEXT')}
        step={1}
        value={count}
        data-hook={dataHooks.cartDishItemAmount}
      />
    </div>
  );

  return (
    <div
      className={`${styles.wrapper} ${isCartImmutable && styles.immutable} ${isLast && styles.last}`}
      data-hook={hook}
      onClick={(e) => {
        if (!isCartImmutable) {
          targetNotInsideRef(e.target, quantityRef) && targetNotInsideRef(e.target, removeRef) && onEdit(e);
        }
      }}
      role="region"
      aria-labelledby={dynamicId}
    >
      <Element name={hook!}>
        <div className={styles.horizontal}>
          {!isCartImmutable ? (
            <div ref={quantityRef} className={styles.quantityWrapper}>
              {quantity}
            </div>
          ) : (
            <Text typography="p2-m" className={styles.quantity_Immutable}>
              <span>{count}</span>
              <span>{'x'}</span>
            </Text>
          )}
          <div className={classnames(styles.content, { [styles.contentOnly]: isCartImmutable })}>
            <Text typography="p2-m" className={styles.title} data-hook={dataHooks.cartDishItemTitle} id={dynamicId}>
              {title}
            </Text>
            {options.map((option, index) => (
              <Text
                key={index}
                typography="p2-s-secondary"
                className={styles.options}
                data-hook={dataHooks.cartVariationChoiceTitle}
              >
                {option}
              </Text>
            ))}
            {comment && (
              <Text
                typography="p2-s-secondary"
                className={styles.comment}
                data-hook={dataHooks.cartDishItemSpecialRequest}
              >
                "{comment}"
              </Text>
            )}
          </div>
          <Text typography="p2-m" className={styles.price} data-hook={dataHooks.cartDishItemPrice}>
            {price}
          </Text>
        </div>
        {!isCartImmutable && (
          <div className={styles.buttons}>
            <TextButton priority={TEXT_BUTTON_PRIORITY.primary} onClick={onEdit} data-hook={dataHooks.cartDishItemEdit}>
              <Text typography="p2-s-colorless">{t('checkout_main_order_edit_button')}</Text>
            </TextButton>
            <div className={styles.spacer} />
            <div ref={removeRef}>
              <TextButton
                priority={TEXT_BUTTON_PRIORITY.primary}
                onClick={onRemove}
                data-hook={dataHooks.cartDishItemRemove}
              >
                <Text typography="p2-s-colorless">{t('checkout_main_order_remove_button')}</Text>
              </TextButton>
            </div>
            <div className={styles.buttonsSpacer} />
            <div className={styles.quantityWrapper}>{quantity}</div>
          </div>
        )}
        {error && (
          <SectionNotification type="error" className={styles.error} data-hook={dataHooks.cartDishItemError}>
            <SectionNotification.Icon icon={<Error />} />
            <SectionNotification.Text>{error}</SectionNotification.Text>
          </SectionNotification>
        )}
      </Element>
    </div>
  );
};

CartDishItem.displayName = 'CartDishItem';

export default CartDishItem;
