import React from 'react';
import CommentInput from '../CommentInput';
import styles from './CartOrderNotes.scss';
import dataHooks from '../../data-hooks';
import { useBi, useTranslation } from 'yoshi-flow-editor-runtime';

export interface CartOrderNotesProps {
  value: string;
  forceInputVisibility?: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
}

const CartOrderNotes: React.FC<CartOrderNotesProps> = ({ value, forceInputVisibility, disabled, onChange }) => {
  const biLogger = useBi();
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonWrapper}>
        <CommentInput
          value={value}
          forceInputVisibility={forceInputVisibility}
          onChange={onChange}
          content={t('cart_button_order_notes')}
          placeholder={t('cart_order_notes_placeholder')}
          dataHook={dataHooks.cartOrderNotes}
          buttonClassName={styles.buttonOrderNotes}
          disabled={disabled}
          onOpen={() => biLogger.cartAddOrderNotes({})}
          onBlur={() => biLogger.cartOrderNotesAdded({ comment: value })}
          fullWidthButton
        />
      </div>
    </div>
  );
};

CartOrderNotes.displayName = 'CartOrderNotes';

export default CartOrderNotes;
