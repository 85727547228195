import { VariationLimitation } from '@wix/restaurants-client-logic';
import { TranslationFunction } from 'yoshi-flow-editor-runtime';

export function limitationToString(
  t: TranslationFunction,
  limitation: VariationLimitation,
  min?: number,
  max?: number,
): string {
  // This function should be replace with translated strings.
  switch (limitation) {
    case 'AT_LEAST_MIN':
      return t('online_ordering_menuitem_selectoptionrequired_minplural', { min });
    case 'AT_LEAST_ONE':
      return t('online_ordering_menuitem_selectoptionrequired_minone');
    case 'EXACTLY':
      return t('online_ordering_menuitem_selectoptionrequired_plural', { min });
    case 'RANGE':
      return t('online_ordering_menuitem_selectoptionrequired_minmax', { min, max });
    case 'UP_TO_ONE':
      return t('online_ordering_menuitem_selectoption_minone1');
    case 'UP_TO_MAX':
      return t('online_ordering_menuitem_selectoption_plural1', { max });
    case 'EXACTLY_ONE':
      return t('online_ordering_menuitem_selectoption_singular1');
    default:
      return '';
  }
}
