import React, { useState } from 'react';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import { useTranslation } from 'yoshi-flow-editor-runtime';
import TextField from '../TextField';
import styles from './DineInDetails.scss';
import ChooseLocationDropdown from '../ChooseLocationDropdown/index';
import { PartialLocation } from '../../../../core/oloApi';
import { getDineInInfo } from '../../../../core/logic/dispatchLogic';
import { getLocationsWithDineIn } from '@wix/restaurants-client-logic';

interface DineInDetailsProps {
  description: string;
  tableNumber: string;
  label: string;
  onChangeTableNumber: (num: string) => void;
  shouldShowError: boolean;
  isMultiLocation: boolean;
  address: string;
  onLocationChange?: (id: string) => void;
  locations?: PartialLocation[];
  selectedLocation?: string;
  appendToElement?: Element;
  lockedDineInLocation?: string;
  showNoLocatonPickedError?: boolean;
  isDineInClosedInCurrentLocation: boolean;
}
export const DineInDetails = (props: DineInDetailsProps) => {
  const { t } = useTranslation();
  const {
    tableNumber,
    shouldShowError,
    onChangeTableNumber,
    isMultiLocation,
    onLocationChange,
    locations,
    selectedLocation,
    lockedDineInLocation,
    appendToElement,
    showNoLocatonPickedError,
    isDineInClosedInCurrentLocation,
  } = props;

  const [label, setLabel] = useState(props.label);
  const [description, setDescription] = useState(props.description);
  const isDineInLocationPicked =
    !isDineInClosedInCurrentLocation &&
    (selectedLocation || (locations && getLocationsWithDineIn(locations)?.length === 1) || lockedDineInLocation);
  const shouldShowPicker = isMultiLocation;
  const shouldShowDineInInfo = (shouldShowPicker && isDineInLocationPicked) || !shouldShowPicker;
  const getExtraContentForLocation = () => {
    return !isMultiLocation ? undefined : getLocationPicker();
  };

  const getLocationPicker = () => (
    <ChooseLocationDropdown
      appendToElement={appendToElement}
      labelText={t('Checkout_main_deliverymethod_DineIn_Location_Text')}
      onChange={onSelectLocation}
      filters="dine-in"
      location={selectedLocation}
      lockedLocation={lockedDineInLocation}
      error={showNoLocatonPickedError}
      isDineInClosedInCurrentLocation={isDineInClosedInCurrentLocation}
      errorMessage={t('delivery_modal_DineIn_Location_ErrorMessage')}
      className={shouldShowDineInInfo ? styles.chooseLocationDropDownWithInfo : styles.chooseLocationDropDown}
    />
  );

  const onSelectLocation = (id: string) => {
    const newLocation = locations?.find((location) => location.currentLocationId === id);
    if (newLocation) {
      const dineInInfo = getDineInInfo(newLocation);
      if (dineInInfo) {
        setLabel(dineInInfo.label);
        setDescription(dineInInfo.instructions);
      }
    }
    onLocationChange && onLocationChange(id);
  };

  return (
    <div className={styles.wrapperDinein} data-hook={dataHooks.dispatchSettingDineInDetailsWrapper}>
      {getExtraContentForLocation()}
      {shouldShowDineInInfo && (
        <React.Fragment>
          <Text typography="p2-m" data-hook={dataHooks.dispatchSettingDineInDescription} className={styles.description}>
            {description}
          </Text>
          <Text typography="p2-m" data-hook={dataHooks.dispatchSettingDineInLabel} className={styles.label}>
            {label + '*'}
          </Text>
          <TextField
            data-hook={dataHooks.dispatchSettingDineInTableNumber}
            value={tableNumber || ''}
            onChange={(e) => onChangeTableNumber(e.target.value || '')}
            className={styles.inputField}
            error={shouldShowError}
            errorMessage={
              shouldShowError ? t('delivery_modal_PickupOption_Curbside_FieldLabel_ErrorMessage') : undefined
            }
            maxLength={40}
            showCharCount
          />
        </React.Fragment>
      )}
    </div>
  );
};
