import React from 'react';
import ModalLayoutEditor from '../ModalLayoutEditor';
import ConnectPaymentSvg from '../ConnectPaymentSvg';
import dataHooks from '../../data-hooks';
import { useBi, useTranslation } from 'yoshi-flow-editor-runtime';

export interface ConnectPaymentModalProps {
  onRequestClose: () => void;
  metaSiteId: string;
}

const ConnectPaymentModal: React.FC<ConnectPaymentModalProps> = ({ onRequestClose, metaSiteId }) => {
  const biLogger = useBi();
  const { t } = useTranslation();

  return (
    <ModalLayoutEditor
      illustration={<ConnectPaymentSvg />}
      header={t('Editor_OOIModal_PaymentMethod_Connect_Title')}
      description={t('Editor_OOIModal_PaymentMethod_Connect_Text')}
      cta={t('Editor_OOIModal_PaymentMethod_Connect_CTA')}
      onCtaClick={() => {
        window.open(`https://www.wix.com/dashboard/${metaSiteId}/payments`);
        biLogger.editorConnectPaymentCta({});
      }}
      footer={t('Editor_OOIModal_PaymentMethod_Connect_disclaimer')}
      onCloseClick={onRequestClose}
      data-hook={dataHooks.connectPaymentModal}
      theme="regular"
    />
  );
};

ConnectPaymentModal.displayName = 'ConnectPaymentModal';

export default ConnectPaymentModal;
