import React from 'react';
import styles from './CheckoutFlowStepTitle.scss';
import CheckmarkSvg from '../CheckmarkSvg';
import Text from '../../core-components/Text';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import dataHooks from '../../data-hooks';
import { useTranslation } from 'yoshi-flow-editor-runtime';

export interface CheckoutFlowStepTitleProps {
  text: string;
  done?: boolean;
  collapsed?: boolean;
  index: string;
  onEdit?: () => void;
  action?: {
    onAction: () => void;
    label: string;
    dataHook?: string;
  };
  editButtonDataHook?: string;
  titleId?: string;
}

const CheckoutFlowStepTitle: React.FC<CheckoutFlowStepTitleProps> = ({
  text,
  done,
  collapsed,
  index,
  onEdit,
  editButtonDataHook,
  titleId,
  action,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.titleWrapper} ${done && styles.done} ${collapsed && styles.collapsed}`}>
      <Text
        typography={collapsed ? 'header-s-secondary' : 'header-s'}
        tagName="div"
        className={`${styles.title} ${done && styles.done}`}
      >
        {done && <CheckmarkSvg className={styles.checkmark} data-hook={dataHooks.checkoutSuccessStep} />}
        <span>
          {!done && `${index}. `} <span id={titleId}>{text}</span>
        </span>
      </Text>
      {done && onEdit && (
        <TextButton
          className={styles.textButton}
          priority={TEXT_BUTTON_PRIORITY.primary}
          onClick={onEdit}
          data-hook={editButtonDataHook}
        >
          {t('checkout_main_order_edit_button')}
        </TextButton>
      )}
      {!done && action?.onAction && (
        <TextButton
          className={styles.textButton}
          priority={TEXT_BUTTON_PRIORITY.primary}
          onClick={action.onAction}
          data-hook={action.dataHook}
        >
          {action.label}
        </TextButton>
      )}
    </div>
  );
};

CheckoutFlowStepTitle.displayName = 'CheckoutFlowStepTitle';

export default CheckoutFlowStepTitle;
