import React from 'react';
import { Text as TpaText, TextProps as TpaTextProps } from 'wix-ui-tpa/Text';
import { st, classes } from './Text.st.css';

export type TextProps = Omit<TpaTextProps, 'typography'> & {
  disabled?: boolean;
  fontSizeOverride?: string;
  typography:
    | 'header-xxs'
    | 'header-xs-responsive'
    | 'header-xs'
    | 'header-s-secondary'
    | 'header-s'
    | 'header-m'
    | 'header-l'
    | 'p2-xs-colorless'
    | 'p2-xs'
    | 'p2-s-colorless'
    | 'p2-s-secondary'
    | 'p2-s-80'
    | 'p2-s-inverse'
    | 'p2-s'
    | 'p2-m-secondary'
    | 'p2-m-colorless'
    | 'p2-m'
    | 'p2-l-60'
    | 'p2-l'
    | 'p3-xs'
    | 'p3-s'
    | 'neutral';
  children?: React.ReactNode;
};
const Text: React.FC<TextProps> = ({ typography, fontSizeOverride = '', disabled = false, children, ...rest }) => {
  return (
    <TpaText {...rest} className={st(classes.root, { typography, disabled, fontSizeOverride }, rest.className)}>
      {children}
    </TpaText>
  );
};

Text.displayName = 'Text';

export default Text;
