import React from 'react';
import styles from './PolicyView.scss';
import { Checkbox } from 'wix-ui-tpa/Checkbox';
import Text from '../../core-components/Text';
import { TEXT_BUTTON_PRIORITY, TextButton } from '../TextButton';
import dataHooks from '../../data-hooks';
import { OpenModalPayload } from '../../../../state/session/session.actions.types';
import { Modals } from '../../../../core/constants';
import { PolicyValue } from '@wix/restaurants-client-logic';
import { Trans, useBi, useTranslation } from 'yoshi-flow-editor-runtime';

export interface PolicyViewProps {
  className?: string;
  hasCheckbox?: boolean;
  checkedByDefault?: boolean;
  termsAndConditions?: PolicyValue;
  privacyPolicy?: PolicyValue;
  onChange: Function;
  openModal: (payload: OpenModalPayload) => void;
  showError?: boolean;
}

function handleClick(
  e: React.UIEvent<HTMLLabelElement>,
  openModal: (payload: OpenModalPayload) => void,
  modal: Modals,
  policyValue?: PolicyValue,
) {
  e.stopPropagation();

  if (policyValue && policyValue.type === 'URL') {
    window.open(policyValue.value, '_blank');
  } else if (policyValue && policyValue.type === 'TEXT') {
    openModal({ modal });
  }
}

const getTransKeyForNoCheckBox = (terms?: boolean, privacy?: boolean) => {
  return terms
    ? privacy
      ? 'checkout_main_orderreview_policy_terms_privacy_orderplaced_text'
      : 'checkout_main_orderreview_terms_orderplaced_text'
    : 'checkout_main_orderreview_policy_privacy_checkbox';
};
const getTransKeyForWithCheckBox = (terms?: boolean, privacy?: boolean) => {
  return terms
    ? privacy
      ? 'checkout_main_orderreview_policy_terms_privacy_checkbox'
      : 'checkout_main_orderreview_policy_terms_checkbox'
    : 'checkout_main_orderreview_policy_privacy_checkbox';
};

const getContent = (hasCheckbox?: boolean, terms?: boolean, privacy?: boolean) => {
  return hasCheckbox ? getTransKeyForWithCheckBox(terms, privacy) : getTransKeyForNoCheckBox(terms, privacy);
};

const PolicyView: React.FC<PolicyViewProps> = ({
  className,
  hasCheckbox,
  checkedByDefault,
  termsAndConditions,
  privacyPolicy,
  onChange,
  openModal,
  showError,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(checkedByDefault);
  const biLogger = useBi();

  const handleTCClick = React.useCallback(
    (e) => {
      biLogger.checkoutReviewPolicies({ type: 'terms-and-conditions' });
      handleClick(e, openModal, Modals.TERMS_AND_CONDITIONS, termsAndConditions);
    },
    [termsAndConditions, openModal, biLogger],
  );

  const handlePPClick = React.useCallback(
    (e) => {
      biLogger.checkoutReviewPolicies({ type: 'privacy-policy' });
      handleClick(e, openModal, Modals.PRIVACY_POLICY, privacyPolicy);
    },
    [privacyPolicy, openModal, biLogger],
  );

  const getLinks = () => {
    const links = [];
    if (termsAndConditions) {
      links.push(
        <TextButton
          priority={TEXT_BUTTON_PRIORITY.primary}
          onClick={handleTCClick}
          data-hook={dataHooks.policyViewTermsAndConditionsButton}
        >
          placeholder
        </TextButton>,
      );
    }
    if (privacyPolicy) {
      links.push(
        <TextButton
          priority={TEXT_BUTTON_PRIORITY.primary}
          onClick={handlePPClick}
          data-hook={dataHooks.policyViewPrivacyPolicyButton}
        >
          placeholder
        </TextButton>,
      );
    }
    return links;
  };

  const content = (
    <Text typography="p2-m">
      <Trans t={t} i18nKey={getContent(hasCheckbox, !!termsAndConditions, !!privacyPolicy)} components={getLinks()} />
    </Text>
  );

  return (
    <div className={`${styles.wrapper} ${className}`} data-hook={dataHooks.policyView}>
      {hasCheckbox ? (
        <div className={styles.checkboxWrapper} data-hook={dataHooks.policyViewCheckboxWrapper}>
          <Checkbox
            onChange={() => {
              const newValue = !checked;
              biLogger.checkoutReviewCheckbox({ lastState: String(newValue) });
              setChecked(newValue);
              onChange && onChange();
            }}
            checked={checked}
            data-hook={dataHooks.policyViewCheckbox}
            label={content}
            error={!checked && showError}
            withFocusRing
          />
          {!checked && showError && (
            <Text
              typography="p2-m"
              className={styles.mustCheck}
              data-hook={dataHooks.checkoutReviewPolicyMustCheckText}
            >
              {t('checkout_main_orderreview_policy_errormessage')}
            </Text>
          )}
        </div>
      ) : (
        content
      )}
    </div>
  );
};

PolicyView.displayName = 'PolicyView';

export default PolicyView;
