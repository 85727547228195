import React from 'react';
import _ from 'lodash';
import TextArea from '../FullWidthTextArea';
import Text from '../../core-components/Text';
import { classes } from './DishModalSpecialRequest.st.css';
import { InjectedTranslateProps, useTranslation } from 'yoshi-flow-editor-runtime';

export interface DishModalSpecialRequestProps extends InjectedTranslateProps {
  value?: string;
  onChange: (comment: string) => void;
  disabled?: boolean;
}

const MaxLength = 120;

const DishModalSpecialRequest: React.FC<DishModalSpecialRequestProps> = ({ value = '', onChange, disabled }) => {
  const [stateValue, setStateValue] = React.useState(value); // prevent cursor bugs
  const { t } = useTranslation();

  return (
    <>
      <Text typography="p2-m" tagName="div" className={classes.specialRequestTitle}>
        <label htmlFor="restaurants.dish-modal-special-request">{`${t('dish_modal_specialrequests')}`}</label>
      </Text>
      <TextArea
        id="restaurants.dish-modal-special-request"
        data-hook="dish-modal-special-requests-input"
        placeholder={t('dish_modal_specialrequests_placeholder')}
        value={stateValue}
        ariaLabel="edit-dish-special-requests-input"
        onChange={(e) => {
          const text = e.target.value.substr(0, MaxLength);
          setStateValue(text);
          onChange(text);
        }}
        onBlur={() => {
          const trimmed = _.trim(stateValue);
          setStateValue(trimmed);
          onChange(trimmed);
        }}
        className={classes.textArea}
        disabled={disabled}
        withFocusRing
      />
    </>
  );
};

DishModalSpecialRequest.displayName = 'DishModalSpecialRequest';

export default DishModalSpecialRequest;
