import React from 'react';
import styles from './CartLoyaltyAppendix.scss';
import Text from '../../core-components/Text';
import { useTranslation } from 'yoshi-flow-editor-runtime';
import dataHooks from '../../data-hooks';

export interface CartLoyaltyAppendixProps {
  customPointsName?: string;
  pointsToBeEarned: number;
}

const CartLoyaltyAppendix: React.FC<CartLoyaltyAppendixProps> = ({ customPointsName, pointsToBeEarned }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <Text typography="p2-s" data-hook={dataHooks.cartLoyaltyMemberPointsToBeEarnedWrapper}>
        {customPointsName
          ? t('cart_promo_loyalty_points_earned_footertext', {
              pointnameplural: customPointsName || 'Points',
              earnednumber: pointsToBeEarned,
            })
          : t('cart_promo_loyalty_points_earned_default_name_footertext', {
              earnednumber: pointsToBeEarned,
            })}
      </Text>
    </div>
  );
};

CartLoyaltyAppendix.displayName = 'CartLoyaltyAppendix';

export default CartLoyaltyAppendix;
