import React from 'react';
import styles from './CartDiscount.scss';
import Text from '../../core-components/Text';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import dataHooks from '../../data-hooks';
import DiscountIcon from '../DiscountIcon';
import DiscountIconV2 from '../DiscountIcon/DiscountIconV2';
import { useBi, useTranslation } from 'yoshi-flow-editor-runtime';
import Dropdown from '../Dropdown';
import classNames from 'classnames';
import CartDiscountIconWrapper from './CartDiscountIconWrapper';

export interface DisplayableReward {
  points: number;
  price: number;
  displayablePrice: string;
}

export interface CartDiscountProps {
  title?: string;
  amount: string;
  description?: string;
  condition?: string;
  forceDescriptionVisibility?: boolean;
  index?: number;
  onRemove?: () => void;
  loyalty?: boolean;
  customPointsName?: string;
  pointsRequired?: number;
  currencyValue?: number;
  pointsBalance?: number;
  displayableRewards: DisplayableReward[];
  onPointsChange?: (points: number, isRemoved?: boolean) => void;
  selectedReward?: number;
  iconUrl?: string;
  useNewIcon?: boolean;
  onLoyaltySaveCTAClick?: () => void;
  onLoyaltyEditCTAClick?: () => void;
}

const CartDiscount: React.FC<CartDiscountProps> = ({
  title,
  amount,
  description,
  condition,
  forceDescriptionVisibility,
  onRemove,
  index = 0,
  loyalty,
  customPointsName,
  pointsBalance,
  displayableRewards = [],
  onPointsChange,
  selectedReward,
  iconUrl,
  useNewIcon,
  onLoyaltySaveCTAClick,
  onLoyaltyEditCTAClick,
}) => {
  const [showMore, setShowMore] = React.useState(forceDescriptionVisibility || loyalty);
  const [showPointSelection, setShowPointSelection] = React.useState(forceDescriptionVisibility);
  const { t } = useTranslation();

  const ref = React.useRef<HTMLSpanElement>(null);
  React.useEffect(() => {
    if (showMore) {
      ref.current && ref.current.focus();
    }
  }, [showMore]);
  const biLogger = useBi();
  const isPromo = onRemove !== undefined;

  const showMoreButton = (
    <TextButton
      priority={TEXT_BUTTON_PRIORITY.primary}
      onClick={() => {
        setShowMore((b) => !b);
        biLogger.cartDiscountDetails({
          action: showMore ? 'hide' : 'show',
          type: isPromo ? 'promo' : 'discount',
        });
      }}
      className={styles.button}
      data-hook={dataHooks.cartDiscountDetails}
    >
      <Text typography="p2-s-colorless">
        {showMore ? t('cart_promo_hide_details_button') : t('cart_promo_show_details_button')}
      </Text>
    </TextButton>
  );

  const loyaltyCTA = (
    <TextButton
      priority={TEXT_BUTTON_PRIORITY.primary}
      onClick={() => {
        setShowPointSelection((b) => !b);
        if (showPointSelection) {
          onLoyaltySaveCTAClick && onLoyaltySaveCTAClick();
        } else {
          onLoyaltyEditCTAClick && onLoyaltyEditCTAClick();
        }
      }}
      className={styles.button}
      data-hook={showPointSelection ? dataHooks.cartLoyaltySaveCTA : dataHooks.cartLoyaltyEditCTA}
    >
      <Text typography="p2-s-colorless">
        {showPointSelection ? t('cart_promo_loyalty_save_cta') : t('cart_promo_loyalty_edit_cta')}
      </Text>
    </TextButton>
  );

  return (
    <div
      className={classNames([styles.wrapper, useNewIcon && styles.newIcon])}
      data-hook={`${dataHooks.cartDiscountItem}-${index}`}
    >
      {iconUrl ? (
        <CartDiscountIconWrapper
          iconUrl={iconUrl}
          artboardSize={useNewIcon ? 30 : 40}
          iconSize={useNewIcon ? 21 : 40}
        />
      ) : useNewIcon ? (
        <DiscountIconV2 />
      ) : (
        <DiscountIcon />
      )}
      <div className={styles.innerWrapper}>
        <div className={styles.content}>
          <div className={styles.textContent}>
            <Text typography="p2-m" className={styles.title} data-hook={`cart-discount-${index}-title`}>
              <span tabIndex={-1} ref={ref}>
                {title}
              </span>
            </Text>
            {showMore && description && (
              <Text typography="p2-s" className={styles.description} data-hook={dataHooks.cartDiscountDescription}>
                {description}
              </Text>
            )}
            {condition && (
              <Text
                typography="p2-s-secondary"
                className={styles.condition}
                data-hook={dataHooks.cartDiscountCondition}
              >
                {condition}
              </Text>
            )}
            {loyalty && !showPointSelection && (
              <div className={styles.pointSelection}>
                <Text
                  typography="p2-s-secondary"
                  className={styles.pointsSelectionLabel}
                  data-hook={dataHooks.loyaltyTitleMember}
                >
                  {customPointsName
                    ? t('cart_promo_loyalty_points_usedfromtotal_text', {
                        usednumber: selectedReward,
                        totalnumber: pointsBalance,
                        pointname: customPointsName,
                      })
                    : t('cart_promo_loyalty_points_usedfromtotal_default_name_text', {
                        usednumber: selectedReward,
                        totalnumber: pointsBalance,
                      })}
                </Text>
              </div>
            )}
          </div>

          <Text typography="p2-m" className={styles.amount} data-hook={`cart-discount-${index}-amount`}>
            {amount}
          </Text>
        </div>
        {showPointSelection && loyalty && (
          <div className={styles.pointSelection}>
            <Text
              typography="p2-s-secondary"
              className={styles.pointsSelectionLabel}
              data-hook={dataHooks.loyaltyTitleMember}
            >
              {t('cart_promo_loyalty_pointusage_dropdown_title')}
            </Text>
            <Dropdown
              upgrade
              data-hook={dataHooks.cartDiscountLoyaltyDropdown}
              options={displayableRewards.map((reward) => ({
                id: String(reward.points),
                value: customPointsName
                  ? t('cart_promo_loyalty_pointusage_dropdown_options', {
                      pointamount: reward.points,
                      pointname: customPointsName,
                      dollaramount: reward.displayablePrice,
                    })
                  : t('cart_promo_loyalty_pointusage_dropdown_default_name_options', {
                      pointamount: reward.points,
                      dollaramount: reward.displayablePrice,
                    }),
                isSelectable: true,
              }))}
              initialSelectedId={String(selectedReward)}
              onChange={({ id }) => onPointsChange?.(Number(id))}
              mobileNativeSelect
            />
          </div>
        )}
        <div className={styles.buttons_wrapper}>
          {description && !loyalty && showMoreButton}
          {loyalty && loyaltyCTA}
          {onRemove && (
            <TextButton
              data-hook={loyalty ? dataHooks.cartRemoveLoyalty : dataHooks.cartRemovePromoCode}
              priority={TEXT_BUTTON_PRIORITY.primary}
              onClick={onRemove}
              className={styles.button}
            >
              <Text typography="p2-s-colorless">{t('cart_button_remove')}</Text>
            </TextButton>
          )}
        </div>
      </div>
    </div>
  );
};

CartDiscount.displayName = 'CartDiscount';

export default CartDiscount;
