import React, { useCallback } from 'react';
import styles from './MenuItemView.scss';
import Text from '../../core-components/Text';
import LabelsView from '../LabelsView';
import dataHooks from '../../data-hooks';
import { MenuDisplayOptions, AlignmentOptions } from '@wix/restaurants-client-logic';
import PriceView from '../PriceView';
import { ThumbnailImage, LoadingBehaviorOptions } from 'wix-ui-tpa/ThumbnailImage';
import { CARD, SIDE_BY_SIDE } from '../../SharedSettings/Constants';
import classNames from 'classnames';
import { useExperiments } from 'yoshi-flow-editor-runtime';

export interface MenuItemViewProps {
  id: string;
  title?: string;
  description?: string;
  price?: string;
  minPrice?: string;
  maxPrice?: string;
  labels?: string[];
  image?: string;
  discount?: string;
  error?: string;
  onClick?: (id: string) => void;
  isMobile?: boolean;
  className?: string;
  layout?: 'side-by-side' | 'card';
  dishCardAlignment?: AlignmentOptions;
  menuDisplayOptions?: MenuDisplayOptions;
  role?: string;
  currency: string;
  hasItemImages?: boolean;
}

function getFixedDimension(isMobile: boolean | undefined, isCardLayout: boolean) {
  if (isMobile) {
    if (isCardLayout) {
      return 178;
    } else {
      return 68;
    }
  } else {
    if (isCardLayout) {
      return 190 * 1.5;
    } else {
      return 178;
    }
  }
}

const MenuItemView: React.FC<MenuItemViewProps> = ({
  id,
  title,
  description,
  price,
  minPrice,
  labels,
  image,
  discount,
  error = '',
  onClick,
  isMobile,
  className,
  layout = SIDE_BY_SIDE,
  dishCardAlignment = AlignmentOptions.EMPTY,
  menuDisplayOptions,
  role,
  currency,
  hasItemImages,
}) => {
  const handleClick = useCallback(
    (e) => {
      // If triggered by mouse click, blur element to override bug on react-modal close
      if (e.nativeEvent.detail) {
        e.currentTarget.blur();
      }

      if (onClick) {
        onClick(id);
      }
    },
    [id, onClick],
  );

  const isCardLayout = layout === CARD;
  const fixedDimension = getFixedDimension(isMobile, isCardLayout);
  const showDishImage = !menuDisplayOptions?.hideDishImages;
  const showDescription = !menuDisplayOptions?.hideDishDescriptions;
  const showLabels = !menuDisplayOptions?.hideMenuItemLabels;
  const { experiments } = useExperiments();
  const placeholderNewLogicEnabled = experiments.enabled('specs.restaurants.placeholder-new-logic');
  // Deliberately using an empty string as alt text.
  // In this particular case adding the dish title/description will not have any effect,
  // since this data is shown directly under the image, and we do not want screen reader to read it twice.
  const alt = '';

  const menuImage = showDishImage && (
    <div data-hook={dataHooks.itemViewImage} className={styles.imageWrapper}>
      {image ? (
        <ThumbnailImage
          src={image}
          {...(isCardLayout
            ? { aspectRatio: 1.5, height: fixedDimension }
            : { width: fixedDimension, height: fixedDimension })}
          alt={alt}
          loadingBehavior={LoadingBehaviorOptions.blur}
          fluid
        />
      ) : (
        <div className={styles.imagePlaceholder} />
      )}
      {error && (
        <div className={styles.mask}>
          <Text typography="p2-m-colorless" className={styles.maskContent}>
            {error}
          </Text>
        </div>
      )}
    </div>
  );

  const MenuImageSTRIP = image && showDishImage && (
    <div data-hook={dataHooks.itemViewImage} className={styles.imageWrapper}>
      <ThumbnailImage
        src={image}
        {...(isCardLayout
          ? { aspectRatio: 1.5, height: fixedDimension }
          : { width: fixedDimension, height: fixedDimension })}
        alt={alt}
        loadingBehavior={LoadingBehaviorOptions.blur}
        fluid
      />
      {error && (
        <div className={styles.mask}>
          <Text typography="p2-m-colorless" className={styles.maskContent}>
            {error}
          </Text>
        </div>
      )}
    </div>
  );

  const MenuImageCARD = hasItemImages && showDishImage && (
    <div data-hook={dataHooks.itemViewImage} className={styles.imageWrapper}>
      {image ? (
        <ThumbnailImage
          src={image}
          {...(isCardLayout
            ? { aspectRatio: 1.5, height: fixedDimension }
            : { width: fixedDimension, height: fixedDimension })}
          alt={alt}
          loadingBehavior={LoadingBehaviorOptions.blur}
          fluid
        />
      ) : (
        <div className={styles.imagePlaceholder} />
      )}
      {error && (
        <div className={styles.mask}>
          <Text typography="p2-m-colorless" className={styles.maskContent}>
            {error}
          </Text>
        </div>
      )}
    </div>
  );

  const LabelsWrapper = layout === CARD ? 'div' : React.Fragment;
  return (
    <div
      className={
        placeholderNewLogicEnabled
          ? classNames([
              styles.wrapper,
              layout === CARD ? styles[layout] : hasItemImages && styles[layout],
              isMobile && styles.mobile,
              className,
            ])
          : classNames([styles.wrapper, styles[layout], isMobile && styles.mobile, className])
      }
      role={role}
      data-hook={dataHooks.itemView(id)}
      onClick={handleClick}
    >
      {layout === CARD && (placeholderNewLogicEnabled ? MenuImageCARD : menuImage)}

      <div className={classNames([styles.contentWrapper, styles[layout], styles[dishCardAlignment]])}>
        <Text
          typography="header-xxs"
          className={styles.title}
          data-hook={dataHooks.itemViewTitle}
          tagName="button"
          fontSizeOverride="dish-title"
        >
          <span className={styles.textTitle}>{title}</span>
        </Text>
        {showDescription && description && (
          <Text
            typography="p2-s"
            className={styles.description}
            data-hook={dataHooks.itemViewDescription}
            fontSizeOverride="dish-description"
          >
            {description}
          </Text>
        )}
        <div className={styles.spacer} />
        {showLabels && (
          <LabelsWrapper>
            <LabelsView
              labels={labels || []}
              className={`${styles.labels} ${styles[layout]} ${styles[dishCardAlignment]}`}
              disabled={Boolean(error)}
            />
          </LabelsWrapper>
        )}
        <PriceView
          typography={error ? 'p2-m-secondary' : 'p2-m'}
          className={styles.price}
          data-hook={dataHooks.itemViewPrice}
          price={minPrice || price}
          suffix={error}
          currency={currency}
        />
      </div>

      {layout === SIDE_BY_SIDE && (placeholderNewLogicEnabled ? MenuImageSTRIP : menuImage)}

      {discount && (
        <Text typography="p2-s-inverse" className={styles.discount}>
          {discount}
        </Text>
      )}
    </div>
  );
};

MenuItemView.displayName = 'MenuItemView';

export default MenuItemView;
