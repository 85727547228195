import { createSelector } from 'reselect';
import { AppState } from '../createStore';

const platformParamsSelector = (state: AppState) => state.platformParams;

export const selectSignedInstance = createSelector(
  [platformParamsSelector],
  (platformParams: AppState['platformParams']) => {
    return platformParams.signedInstance;
  },
);
