import _ from 'lodash';
import React from 'react';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import Checkbox, { CheckboxTheme } from '../Checkbox';
import Error from 'wix-ui-icons-common/on-stage/Error';
import Text from '../../core-components/Text';
import styles from './VariationView.scss';
import VariationLimitationView from '../VariationLimitationView';
import dataHooks from '../../data-hooks';
import RadioButton, { RadioButtonTheme } from '../RadioButton';
import { Element } from 'react-scroll';
import { DisplayableVariation, UnavailabileVariationReason, VariationPath } from '@wix/restaurants-client-logic';
import PriceView from '../PriceView';

export interface VariationViewProps {
  displayableVariation: DisplayableVariation;
  onChange: (variationPath: VariationPath, choiceId: string, clientSideId: string) => void;
  indentation?: number;
  shouldDisplayError: boolean;
  variationsWithVisibleErrors: Record<string, boolean>;
  currency: string;
}

const VariationView: React.FC<VariationViewProps> = ({
  displayableVariation,
  onChange,
  indentation = 0,
  shouldDisplayError,
  variationsWithVisibleErrors,
  currency,
}) => {
  const indentationClassName = styles[`indentation${_.clamp(indentation, 0, 4)}`];

  const variationLimitationView = (
    <VariationLimitationView
      limitation={displayableVariation.limitation}
      min={displayableVariation.min}
      max={displayableVariation.max}
      className={styles.limitation}
    />
  );

  const idSuffix = Math.random();
  const dynamicId = `variation-view-${idSuffix}`;

  const { hasEnoughSelectableChoices, reasons = [] } = displayableVariation.error || {};

  const choicesToShow = displayableVariation.choices.filter(
    (choice) =>
      (choice.errors.length === 0 || choice.errors.filter((err) => err.type !== 'order_delivery_time').length === 0) &&
      !choice.isDishDeleted,
  );
  const hasChoicesToShow =
    choicesToShow.length > 0 &&
    (hasEnoughSelectableChoices ||
      reasons.filter(
        (reason) =>
          reason === UnavailabileVariationReason.SoldOut || reason === UnavailabileVariationReason.UnavailableTime,
      ).length > 0);

  return hasChoicesToShow ? (
    <section
      className={`${styles.wrapper} ${indentationClassName}`}
      data-hook={dataHooks.variationView(displayableVariation.clientSideId)}
      aria-labelledby={dynamicId}
    >
      <Element name={displayableVariation.clientSideId}>
        <Text typography="p2-m" data-hook={dataHooks.variationViewTitle} className={styles.title} id={dynamicId}>
          {displayableVariation.title}
        </Text>
      </Element>
      {variationLimitationView && <Text typography="p2-s-80">{variationLimitationView}</Text>}
      {shouldDisplayError && !displayableVariation.isValid && (
        <div data-hook={dataHooks.variationViewError} className={styles.error}>
          <SectionNotification type="error">
            <SectionNotification.Icon icon={<Error />} />
            <SectionNotification.Text>{variationLimitationView}</SectionNotification.Text>
          </SectionNotification>
        </div>
      )}
      <div className={styles.spacer8} />
      {choicesToShow.map((choice) => {
        const shouldBeDisabled = choice.errors.length > 0;

        const isRadio = displayableVariation.type === 'variation.radio';
        const selectedChoices = displayableVariation.choices.filter((c) => c.isSelected && c.isSelectable);
        const disabled =
          shouldBeDisabled ||
          (!isRadio &&
            !choice.isSelected &&
            Number.isInteger(displayableVariation.max) &&
            selectedChoices.length >= displayableVariation.max);

        const selectionComponentProps = {
          className: styles.selectionComponent,
          label: choice.title,
          name: isRadio ? displayableVariation.clientSideId : choice.clientSideId,
          value: choice.clientSideId,
          onChange: () =>
            onChange(displayableVariation.variationPath, choice.itemId, displayableVariation.clientSideId),
          checked: choice.isSelected && choice.isSelectable,
          suffix: <PriceView typography="neutral" price={choice.formattedPrice} currency={currency} withPlusSign />,
          disabled: displayableVariation.disabled || disabled,
          'data-hook': dataHooks.variationViewChoice(choice.clientSideId),
        };

        return (
          <React.Fragment key={choice.clientSideId}>
            <div style={{ marginBottom: '8px' }}>
              {isRadio ? (
                <RadioButton {...selectionComponentProps} theme={RadioButtonTheme.Box} withFocusRing />
              ) : (
                <Checkbox {...selectionComponentProps} theme={CheckboxTheme.Box} withFocusRing />
              )}
            </div>

            {choice.variations.map((dv) => (
              <VariationView
                key={dv.clientSideId}
                displayableVariation={dv}
                onChange={onChange}
                indentation={indentation + 1}
                variationsWithVisibleErrors={variationsWithVisibleErrors}
                shouldDisplayError={variationsWithVisibleErrors[displayableVariation.clientSideId]}
                currency={currency}
              />
            ))}
          </React.Fragment>
        );
      })}
    </section>
  ) : null;
};

VariationView.displayName = 'VariationView';

export default VariationView;
