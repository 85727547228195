import React from 'react';
import { TranslationFunction, useTranslation } from 'yoshi-flow-editor-runtime';
import Text, { TextProps } from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import styles from './CartTitle.scss';

export type CartTitleProps = Partial<Pick<TextProps, 'typography' | 'className'>> & {
  itemCount: number;
  displayViewOrderButton?: boolean;
  onViewOrderButtonClick: () => void;
};

function getHeaderText(itemCount: number, t: TranslationFunction): string {
  return t('checkout_main_my_order', { number: itemCount });
}

const CartTitle: React.FC<CartTitleProps> = ({
  itemCount,
  className,
  typography = 'header-xs',
  displayViewOrderButton,
  onViewOrderButtonClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <Text
        typography={typography}
        className={`${displayViewOrderButton && styles.stretch}`}
        data-hook={dataHooks.cartTitle}
      >
        {getHeaderText(itemCount, t)}
      </Text>
      {displayViewOrderButton && (
        <TextButton
          onClick={onViewOrderButtonClick}
          priority={TEXT_BUTTON_PRIORITY.primary}
          className={styles.button}
          data-hook={dataHooks.cartTitleViewOrderButton}
        >
          <Text typography="p2-s-colorless">{t('checkout_main_view_order')}</Text>
        </TextButton>
      )}
    </div>
  );
};

CartTitle.displayName = 'CartTitle';

export default CartTitle;
