import React from 'react';
import ModalLayoutBasic from '../ModalLayoutBasic';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import Button from '../Button';
import styles from './TemplatesModal.scss';
import { useTranslation } from 'yoshi-flow-editor-runtime';

export interface TemplatesModalProps {
  onRequestClose: () => void;
}

const TemplatesModal: React.FC<TemplatesModalProps> = ({ onRequestClose }) => {
  const { t } = useTranslation();
  const footer = (
    <Button
      upgrade
      fullWidth
      data-hook={dataHooks.templatesModalGotItButton}
      onClick={() => {
        onRequestClose();
      }}
      className={styles.footer}
    >
      {t('checkout_viewer_template_thankyoupage_cta')}
    </Button>
  );

  return (
    <ModalLayoutBasic
      header={t('checkout_viewer_template_thankyoupage_title')}
      footer={footer}
      onCloseClick={() => onRequestClose()}
      data-hook={dataHooks.templatesModal}
    >
      <Text data-hook={dataHooks.templatesModalDescription} typography="p2-m">
        {t('checkout_viewer_template_thankyoupage_description')}
      </Text>
    </ModalLayoutBasic>
  );
};

TemplatesModal.displayName = 'TemplatesModal';

export default TemplatesModal;
