import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'yoshi-flow-editor-runtime';

import TextField from '../TextField';
import dataHooks from '../../data-hooks';
import { AddOrderCouponPayload } from '../../../../state/cart/cart.actions.types';
import styles from './CartCoupon.scss';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import Text from '../../core-components/Text';
import Button from '../Button';

export interface CartCouponProps {
  error?: string;
  addOrderCoupon: (payload: AddOrderCouponPayload) => void;
  removeOrderCoupon: (payload: void) => void;
  forceInputVisibility?: boolean;
  forceInputValue?: string;
  disabled?: boolean;
  biLogger?: any;
  isMobile: boolean;
  isRTL?: boolean;
}

interface CartCouponState {
  couponText: string;
  isExpanded: boolean;
}

const MaxLength = 20;

const CartCoupon = (props: CartCouponProps) => {
  const { t } = useTranslation();
  const focusOnError = useRef(false);
  const inputRef = useRef<HTMLInputElement>();

  const [state, setState] = React.useState<CartCouponState>({ couponText: '', isExpanded: false });

  useEffect(() => {
    if (props.error && focusOnError.current) {
      /* remove 👇 after yoshi5 migration */
      /* eslint-disable-next-line no-unused-expressions */
      inputRef.current?.focus();
      focusOnError.current = false;
    }
  }, [props.error, focusOnError.current]);

  const handleBlur = () => {
    if (!getTextFieldValue()) {
      props.removeOrderCoupon();
    }
  };

  const getTextFieldValue = () => {
    return props.forceInputValue || state.couponText;
  };

  const handleCouponCodeChange = (e: { target: { value: string } }) => {
    const couponText = e.target.value
      .substr(0, MaxLength)
      .replace(/[^0-9a-z\-_]/gi, '')
      .toUpperCase();

    setState((currentState) => ({ ...currentState, couponText }));
  };

  const handleClickOnAddCoupon = () => {
    props.biLogger && props.biLogger.cartAddPromoCode({});
    setState((currentState) => ({ ...currentState, isExpanded: true }));
  };

  const { couponText, isExpanded } = state;
  const { addOrderCoupon, disabled, error, forceInputVisibility, isMobile, isRTL } = props;
  const errorTooltipPlacement = isMobile ? 'top' : isRTL ? 'top-start' : 'top-end';

  return (
    <div className={styles.wrapper}>
      {isExpanded || forceInputVisibility ? (
        <form
          data-hook={dataHooks.cartPromoCodeForm}
          className={styles.addCouponForm}
          onSubmit={(e) => {
            e.preventDefault();
            addOrderCoupon({ coupon: couponText });
            focusOnError.current = true;
          }}
        >
          <Text typography="p2-s" className={styles.label}>
            {t('cart_promo_title')}
          </Text>
          <div className={styles.horizontal}>
            <TextField
              inputRef={(e) => (inputRef.current = e)}
              className={styles.couponInput}
              value={getTextFieldValue()}
              onChange={handleCouponCodeChange}
              data-hook={dataHooks.cartPromoCodeInput}
              placeholder={t('cart_promo_placeholder')}
              aria-label={t('cart_promo_placeholder')}
              error={!!error}
              errorMessage={t(error || '')}
              autoFocus
              onBlur={handleBlur}
              errorTooltipPlacement={errorTooltipPlacement}
            />
            <Button type="submit" upgrade data-hook={dataHooks.cartSubmitPromoCode}>
              {t('cart_promo_cta')}
            </Button>
          </div>
        </form>
      ) : (
        <div className={styles.buttonWrapper}>
          <TextButton
            priority={TEXT_BUTTON_PRIORITY.primary}
            onClick={handleClickOnAddCoupon}
            data-hook={dataHooks.cartAddPromoCode}
            className={styles.fullWidthButton}
            disabled={disabled}
          >
            <Text typography="p2-m-colorless">{t('cart_button_promo')}</Text>
          </TextButton>
        </div>
      )}
    </div>
  );
};

export default CartCoupon;
