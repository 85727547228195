import React from 'react';
import Quantity from '../Quantity';
import { PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import styles from './DishModalFooter.scss';
import dataHooks from '../../data-hooks';
import Button from '../Button';
import Text from '../../core-components/Text';
import { DisplayableOrderItem } from '@wix/restaurants-client-logic';
import { Trans, useExperiments, useTranslation } from 'yoshi-flow-editor-runtime';
import PriceView from '../PriceView';

export const ADD_TO_MY_ORDER_TEXT = 'Add to my order';

export interface DishModalFooterPropagatedProps {
  isEditingItemFromTheCart: boolean;
  priceDifference?: string;
}
export interface DishModalFooterProps {
  onSubmitButtonClick: () => void;
  displayableOrderItem: DisplayableOrderItem;
  onQuantityChange: (quantity: number) => void;
  currency: string;
}

const DishModalFooter: React.FC<DishModalFooterProps & DishModalFooterPropagatedProps> = ({
  onSubmitButtonClick,
  displayableOrderItem,
  onQuantityChange,
  isEditingItemFromTheCart,
  priceDifference,
  currency,
}) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const shouldUsePriceView = experiments.enabled('specs.restaurants.olo-client-dish-modal-price-a11y');

  const getAddToOrderButtonText = () => {
    return isEditingItemFromTheCart ? getEditDishText() : getAddNewDishText();
  };

  const getAccessibleCta = (i18nKey: string, price: string, suffix?: string) => {
    return (
      <Trans
        t={t}
        i18nKey={i18nKey}
        components={[<PriceView currency={currency} typography="p2-m-colorless" price={price} suffix={suffix} />]}
      />
    );
  };

  const getEditDishText = () => {
    const totalPrice = displayableOrderItem.totalPrice;
    const suffix = `(${priceDifference!.includes('-') ? '' : '+'}${priceDifference})`;
    const priceStr = `${totalPrice} ${suffix}`;

    return shouldUsePriceView
      ? getAccessibleCta('accessibility_checkout_main_updateorder_cta', totalPrice, suffix)
      : t('checkout_main_updateorder_cta', { price: priceStr, interpolation: { escapeValue: false } });
  };

  const getAddNewDishText = () => {
    return shouldUsePriceView
      ? getAccessibleCta('accesibility_online_ordering_menuitem_addtoorder_button', displayableOrderItem.totalPrice)
      : t('online_ordering_menuitem_addtoorder_button', {
          price: displayableOrderItem.totalPrice,
          interpolation: { escapeValue: false },
        });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.quantity}>
        <Quantity
          data-hook={dataHooks.dishModalCounter}
          value={displayableOrderItem.quantity}
          onChange={(val) => {
            Number(val) > 0 && Number(val) < 1000 && onQuantityChange(Number(val));
          }}
          aria-label={t('DISH_SCREEN_SCREENREADER_QTYSELECTED_TEXT')}
          inputAriaLabel={t('DISH_SCREEN_QUANTITY')}
          incrementAriaLabel={t('DISH_SCREEN_SCREENREADER_INCREASE_TEXT')}
          decrementAriaLabel={t('DISH_SCREEN_SCREENREADER_DECREASE_TEXT')}
          step={1}
          min={1}
          disabled={displayableOrderItem.submitDisabled}
        />
      </div>
      <div className={styles.spacer} />
      <Button
        className={styles.button}
        upgrade
        fullWidth
        disabled={displayableOrderItem.submitDisabled}
        data-hook={dataHooks.dishModalAddToCart}
        onClick={onSubmitButtonClick}
        priority={PRIORITY.primary}
        size={SIZE.large}
      >
        <Text typography="p2-m-colorless">{getAddToOrderButtonText()}</Text>
      </Button>
    </div>
  );
};

DishModalFooter.displayName = 'DishModalFooter';

export default DishModalFooter;
